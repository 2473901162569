var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "not-found" },
    [
      _vm.loading404
        ? _c("loading-screen")
        : _c("div", { staticClass: "content" }, [
            _c("h2", [_vm._v("Page Not Found")]),
            _c("div", { staticClass: "lobby-link" }, [
              _c("span", [_vm._v("Click below to go back to your Space")]),
              _c("img", {
                staticClass: "back-to-area-icon",
                attrs: {
                  alt: "back to space button",
                  src: require("@/assets/enter-room-icon.svg"),
                },
                on: { click: _vm.goToHome },
              }),
              _c("br"),
              _c("br"),
              _c("br"),
              _c("br"),
              _c("span", [
                _vm._v(" You will be automatically redirected soon"),
              ]),
            ]),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }