<template>
  <div class="not-found">
    <loading-screen v-if="loading404"/>
    <div v-else class="content">
      <h2>Page Not Found</h2>
      <div class="lobby-link">
        <span>Click below to go back to your Space</span>
        <img
          alt="back to space button"
          class="back-to-area-icon"
          src="@/assets/enter-room-icon.svg"
          @click="goToHome"
        />
        <br />
        <br />
        <br />
        <br />
        <span>
          You will be automatically redirected soon</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import LoadingScreen from '@/components/global/loading/LoadingScreen.vue';

export default {
  name: "App",
  components: { LoadingScreen },
  data() {
    return {
      timeout: null,
    };
  },
  computed: {
    ...mapGetters({
      loading404: 'loading/isLoading404'
    }),
  },
  watch: {
    loading404() {
      this.initReRouteTimeout()
    }
  },
  methods: {
    ...mapActions('routing', ['goToHome']),
    initReRouteTimeout() {
      if(!this.loading404) {
        this.timeout = setTimeout(() => {
          this.goToHome()
        }, 2000)
      }
    }
  },
  mounted() {
    this.initReRouteTimeout()
  },
  beforeDestroy() {
    clearTimeout(this.timeout)
  }
};
</script>

<style lang="scss">
.not-found {
  height: 100%;
  width: 100%;
  display: grid;
}
.content {
  display: grid;
  align-content: center;
  justify-items: center;
  text-align: center;
  grid-gap: 4rem;

  .lobby-link {
    display: grid;
    grid-gap: 1rem;
    justify-items: center;
  }
  .back-to-area-icon {
    height: 5rem;
  }
}
</style>
